import './App.css';
import Login from "./components/login/Login.js";
import ChangePass from "./components/change-password/ChangePass.js";
import Signup from "./components/signup/Signup.js";
import Profile from "./components/profile/Profile.js";
import Landing from "./components/landing/Landing.js";
import Verification from './components/verification/Verification.js';
import { BrowserRouter as Router , Routes , Route ,Link } from 'react-router-dom';

function App() {
  return (
    <Router>
      <nav></nav>
      <Routes>
        <Route path='/' element={<Login/>} />
        <Route path='/signup' element={<Signup/>} />
        <Route path='/ChangePass' element={<ChangePass/>} />
        <Route path='/profile' element={<Profile/>} />
        <Route path='/landing' element={<Landing/>} />
        <Route path='/verification' element={<Verification/>} />
      </Routes>
    </Router>
   
  );  
}

export default App;
