const data = {
  floors: [
  { id:1,src: require('./assets/images/Rectangle39.png'), text: 'light shade butterscotch oak' },
  { id:2, src: require('./assets/images/Rectangle40.png'), text: 'dark shade butterscotch oak' },
  { id:3,src: require('./assets/images/Rectangle41.png'), text: 'shade type' },
  { id:4,src: require('./assets/images/Rectangle42.png'), text: 'Floor 4 Description' },
  ],
  walls: [
    { id:1, src: require("./assets/images/Rectangle391.png"), text: "#shade type" },
    { id:2,src: require("./assets/images/Rectangle401.png"), text: "#shade type" },
    { id:3,src: require("./assets/images/Rectangle411.png"), text: "#shade type" },
    { id:4, src: require("./assets/images/Rectangle421.png"), text: "#shade type" },
  ],
  sinks: [
    { id:1, src: require("./assets/images/Rectangle392.png"), text: "#shade type" },
    {  id:2,src: require("./assets/images/Rectangle402.png"), text: "#shade type" },
    {  id:3,src: require("./assets/images/Rectangle412.png"), text: "#shade type" },
    {  id:4,src: require("./assets/images/Rectangle422.png"), text: "#shade type" },
  ],
  faucets:[
    {  id:1,src: require("./assets/images/Rectangle393.png"), text: "#shade type" },
    {  id:2,src: require("./assets/images/Rectangle403.png"), text: "#shade type" },
    {  id:3,src: require("./assets/images/Rectangle413.png"), text: "#shade type" },
    {  id:4,src: require("./assets/images/Rectangle423.png"), text: "#shade type" },
  ],
  kitchens:[ 
  { id:1, src: require("./assets/images/Rectangle394.png"), text: "#shade type" },
  {  id:2,src: require("./assets/images/Rectangle404.png"), text: "#shade type" },
  {  id:3,src: require("./assets/images/Rectangle414.png"), text: "#shade type" },
  {  id:4,src: require("./assets/images/Rectangle424.png"), text: "#shade type" },],
  shrubs:[
    {  id:1,src: require("./assets/images/Rectangle395.png"), text: "#shade type" },
    {  id:2,src: require("./assets/images/Rectangle405.png"), text: "#shade type" },
    {  id:3,src: require("./assets/images/Rectangle415.png"), text: "#shade type" },
    {  id:4,src: require("./assets/images/Rectangle425.png"), text: "#shade type" },
  ],
  pavements:[
    { id:1, src: require("./assets/images/Rectangle396.png"), text: "#shade type" },
    { id:2, src: require("./assets/images/Rectangle406.png"), text: "#shade type" },
    { id:3, src: require("./assets/images/Rectangle416.png"), text: "#shade type" },
    { id:4, src: require("./assets/images/Rectangle426.png"), text: "#shade type" },
  ],
  interior:[
    { id:1, src: require("./assets/images/Rectangle 55.png"), text: "#shade type" },
    { id:2, src: require("./assets/images/Rectangle 56.png"), text: "#shade type" },
    { id:3, src: require("./assets/images/Rectangle 57.png"), text: "#shade type" },
    { id:4, src: require("./assets/images/Rectangle 58.png"), text: "#shade type" },
    { id:5, src: require("./assets/images/Rectangle 59.png"), text: "#shade type" },
    { id:6, src: require("./assets/images/Rectangle 60.png"), text: "#shade type" },
  ],
  exterior:[
    { id:1, src: require("./assets/images/Rectangle 61.png"), text: "#shade type" },
    { id:2, src: require("./assets/images/Rectangle 62.png"), text: "#shade type" },
    { id:3, src: require("./assets/images/Rectangle 63.png"), text: "#shade type" },
    { id:4, src: require("./assets/images/Rectangle 64.png"), text: "#shade type" },
    { id:5, src: require("./assets/images/Rectangle 65.png"), text: "#shade type" },
    { id:6, src: require("./assets/images/Rectangle 66.png"), text: "#shade type" },
  ]
};
export default data;