import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import "../landing/landing.css";
import myVideo from "../../assets/video/videovideo.mp4";
import Header, { Button, InputField } from "../constant.js";
import SectionGallery from "./LandingConstant.js";
import data from "../../data.js";

function Landing() {
  const [interior, setInterior] = useState(data.interior);
  const [exterior, setExterior] = useState(data.exterior);
  return (
    <div className="container flex">
      <div className="top-container-profile flex">
        <Header />
      </div>
      <div className="bottom-container-landing flex">
        <div className="photo-landing-div">
          <div className="h1-landing-photo">
            <h1>
              transform your vision into reality with our expert construction
              and landscaping services where quality meets creativity.
            </h1>
          </div>

          <input type="text" placeholder="search..." className="search-input-landing" />
        </div>
      </div>
      <div className="landscaping-Department-div">
        <h1>INTERIOR CLADDING</h1>
      </div>
      <div>
        {/* Floors Section */}
        <SectionGallery title="FLOORS" items={data.floors} />
        {/* Walls Section */}
        <SectionGallery title="WALLS" items={data.walls} />
        {/* Sinks Section */}
        <SectionGallery title="SINKS" items={data.sinks} />
        {/* Faucets Section */}
        <SectionGallery title="FAUCETS" items={data.faucets} />
        {/* Kitchens Section */}
        <SectionGallery title="KITCHENS" items={data.kitchens} />
      </div>
      <div className="landscaping-Department-div">landscaping Department</div>
      {/* shrubs Section */}
      <SectionGallery title="shrubs" items={data.shrubs} />
      {/* pavements Section */}
      <SectionGallery title="shrubs" items={data.pavements} />
      <div className="landscaping-Department-div">
        <h1>ABOUT US AND OUR SERVICES</h1>
      </div>
      <div className="aboutus-container flex">
        <div className="aboutus-row-container flex">
          <span>
            <h1>Welcome</h1>
            <p>
              to Z and N Construction and Landscaping, where quality meets
              creativity. Founded with a passion for transforming outdoor spaces
              and building lasting structures, we are dedicated to providing
              exceptional services that enhance both the beauty and
              functionality of your property.
            </p>

            <h3>Our Mission</h3>
            <p>
              At Z and N, our mission is to deliver high-quality construction
              and landscaping solutions tailored to meet the unique needs of our
              clients. We believe in creating spaces that reflect your vision
              while ensuring durability and sustainability.
            </p>

            <h3>Our Team</h3>
            <p>
              Our experienced team of professionals brings a wealth of knowledge
              and expertise to every project. From skilled landscapers to expert
              builders, we work collaboratively to ensure that each job is
              completed to the highest standards. We pride ourselves on our
              attention to detail, commitment to excellence, and passion for our
              craft.
            </p>
          </span>

          <span>
            <h1>What We Offer</h1>
            <p>
              Construction Services: From residential projects to commercial
              builds, we handle everything from design to execution, ensuring a
              seamless construction process. Landscaping Services: Our
              landscaping team specializes in creating beautiful gardens,
              patios, and outdoor living spaces that enhance your property’s
              appeal.
            </p>
            <h1>Why Choose Us?</h1>
            <p>
              Quality Craftsmanship: We use only the best materials and
              techniques to ensure lasting results. Customer-Centric Approach:
              Your satisfaction is our priority. We listen to your needs and
              work closely with you throughout the project. Sustainable
              Practices: We are committed to environmentally friendly practices
              that benefit both our clients and the planet. Join us in creating
              beautiful, functional spaces that you can cherish for years to
              come. At Z and N Construction and Landscaping, we turn your dreams
              into reality.
            </p>
          </span>
        </div>
        <div className="video-container">
          <video controls width="100%" height="auto">
            <source src={myVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="landscaping-Department-div">
        <h1>BUSINESS EXHIBITION</h1>
      </div>
      <div className="business-section flex">
        <div className="product-card flex">
          <div className="product-card-row flex">
            <h1>INTERIOR</h1>
          </div>
          <button type="button" className="button-viewall">
            VIEW ALL
          </button>
          <div className="image-business-gallery">
            {interior.map((item, index) => (
              <div key={index} className="gallery-business-item">
                <div className="image-product-container">
                  <img src={item.src} alt={`interior ${index}`} />
                </div>
                <div className="overlay-text">
                  <h2>{item.text}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="product-card flex">
          <div className="product-card-row flex">
            <h1>EXTERIOR</h1>
          </div>
          <Button className="button-viewall" />
          VIEW ALL
          <div className="image-business-gallery">
            {exterior.map((item, index) => (
              <div key={index} className="gallery-business-item">
                <div className="image-product-container">
                  <img src={item.src} alt={`exterior ${index}`} />
                </div>
                <div className="overlay-text">
                  <h2>{item.text}</h2>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="landscaping-Department-div">
        <div className="contact-div flex">
          <h2>CONTACT US:</h2>
          <h2>1234 Greenway Drive Springfield, IL 62701</h2>
          <h2>(555) 123-4567</h2>
          <h2></h2>
        </div>
      </div>
      <footer className="footer-landing flex">
        <div className="footer-content-one-landing flex">
          <div className="part-one-footer flex">
            <img src={logo} alt="image-footer" className="image-footer" />
            <h1 className="h1-footer">
              THE CONSTRUCTION AND LANDSCAPING COMPANY
            </h1>
          </div>

          <div className="part-two-footer">
            <h3>Business Hours: </h3> <hr />
            <h3> Monday - Friday: 8 AM - 6 PM</h3>
            <hr />
            <h3>Saturday: 9 AM - 4 PM</h3>
            <hr />
            <h3>Sunday: Closed</h3>
          </div>
          <div className="part-three-footer">
            <a href="#">QUICK LINKS:</a>
            <hr />
            <a href="#">HOME</a>
            <hr />
            <a href="#">ABOUT US</a>
            <hr />
            <a href="#">SERVICES</a>
            <hr />
            <a href="#">PROJECTS</a>
            <hr />
            <a href="#">CONTACT</a>
            <hr />
          </div>
          <div className="part-two-footer">
            <h3>Awards and Certifications: </h3> <hr />
            <h3>
              “Proud member of the National Association of Landscape
              Professionals“
            </h3>
            <hr />
            <h3>“Certified Green Builder“</h3>
            <hr />
            <h3>Sunday: Closed</h3>
          </div>
        </div>
        <div className=""></div>
      </footer>
    </div>
  );
}
export default Landing;
