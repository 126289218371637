import React , {useState} from "react";
import "../profile/profile2.css";
import logo from '../../assets/images/logo.png';
import pale from '../../assets/images/pale-85.png';
import profile from '../../assets/images/Ellipse10.png';
import Landing from './../landing/Landing';
import Header from "../constant.js";
import { useNavigate } from "react-router-dom";

  function Profile() {
    
    const navigate = useNavigate();
  
   
    const handleLogout = () => {
      navigate("/"); // الانتقال لصفحة login
    };
  return (
      <div className="container flex">
        <div className="top-container-profile flex">
        <Header/>
        </div>
        <div className="bottom-container-profile flex">
          <div className="top-section-profile">
          </div>
          <div className="bottom-section-profile flex">
            <div className="left-bottom-section-profile flex">
            <div className="profile-image">
              <img src={pale} alt="photo"/>
              </div>
              <div className="text flex">
              <h3 className="h3-profile">NAME</h3> 
              <h3 className="h3-profile">COUNTRY</h3> 
              <h3 className="h3-profile">EMAIL</h3> 
              <h3 className="h3-profile">PHONE</h3>
              </div>
              
            </div>
            <div className="right-bottom-section-profile flex">
            <div className="text-bottom flex">
              <h3 className="h3-profile-bottom">JAMES WILLIAMS</h3> 
              <h3 className="h3-profile-bottom">USA - WASHINGTON, D.C.</h3> 
              <h3 className="h3-profile-bottom">JAMES96@GMAIL.COM</h3> 
              <h3 className="h3-profile-bottom">+1 202 555 1234</h3>
              </div>
            </div>
          </div>
          <img src={profile} alt="profile-photo " className="profile-photo"/>
          <div className="profile-name flex">@JAMES_WILLIAMS</div>
          <button className="button-profile-logout" onClick={handleLogout} >LOG OUT</button>
        </div>
      </div>
    
  );
}

export default Profile;
