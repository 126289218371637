import React ,{ useState } from "react";
import "./verification.css";
import circle2 from "../../assets/images/Ellipse7.png";
import mail from "../../assets/images/morphis-mail-with-a-frosted-glass-effect-1.png";
import circle1 from "../../assets/images/Ellipse8.png";
import { Link} from "react-router-dom";
import {Button} from "../constant.js";
function Verification() {
  
  const [code, setCode] = useState(["", "", "", "" ,"",""]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value) || value === "") { // التأكد من إدخال أرقام فقط
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      // الانتقال للحقل التالي تلقائيًا
      if (value && index < code.length - 1) {
        document.getElementById(`code-input-${index + 1}`).focus();
      }
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("Text").slice(0, 4);
    if (/^\d{4}$/.test(pasteData)) {
      setCode(pasteData.split(""));
    }
  };

  return (
    <div className="container-verification">
      <header className="headerone flex" />
      <div className="bottom-section-verification flex">
        <img src={circle1} alt="" className="circle2" />
        <img src={mail} alt="" className="mail" />
        <img src={circle2} alt="" className="circle1" />
        <h2>please verify account</h2>
        <h3>enter the 6 digit code (contains letters and numbers) that we sent to your email address to verify your new account</h3>
        <div className="container-input-code">
      {code.map((digit, index) => (
        <input
          key={index}
          id={`code-input-${index}`}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          onPaste={handlePaste}
          className="input-verification"
          
        />
      ))}
    </div>
    <h3>valid for 3 minutes</h3>
    <Link to="#" className="link-verification">RESEND CODE </Link>
    <Button onClick={""}  label="VERIFY AND CONTINUE" />
      </div>
    </div>
  );
}

export default Verification;
