import React from "react";
import "../change-password/changepass.css";
import logo from "../../assets/images/logo.png";
import { Button, InputField } from "../constant";

function ChangePass() {
  return (
    <div className="container flex">
      <header className="headerone flex" />
      <div className="bottom-section flex ">
        <div className="left-section-pass flex">
          <div className="div-circle "></div>
          <img className="logo" src={logo} alt="Logo" />
          <h2 className="subtitle-change">
            The Construction and landscaping company
          </h2>
        </div>
        <div className="right-section-pass flex">
          <h1 className="h1">CHANGE PASSWORD</h1>
          
            <InputField
              label={"EMAIL ADDRESS"}
              icon={""}
              type={"email"}
              placeholder={"example@gmail.com"}
              className={"input-with-icon"}
            />
            <InputField
              label={"PASSWORD"}
              icon={""}
              type={"password"}
              placeholder={"*******************"}
              className={"input-with-icon"}
            />
              <InputField
              label={"RE-ENTER PASSWORD"}
              icon={""}
              type={"password"}
              placeholder={"*******************"}
              className={"input-with-icon"}
            />
         
          <h2 className="h2">
            we will send you a 6 digit code to verify your account
          </h2>
         
            <Button className="flex" label={"SEND CODE"} />
          
        </div>
      </div>
      <div></div>
    </div>
  );
}
export default ChangePass;
