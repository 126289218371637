import React from "react";
import "../landing/landing.css";


const SectionGallery = ({ title, items }) => (
  <div className="floors-section flex">
    <div className="title-floors flex">
      <div className="rectangular-div"></div>
      <h1>{title}</h1>
      <button type="button" className="button-viewall">VIEW ALL</button>
    </div>
    <div className="image-gallery">
      {Array.isArray(items) && items.map((item) => (
        <div key={item.id} className="gallery-item">
          <div className="image-container">
            <img src={item.src} alt={`${title} ${item.id}`} />
          </div>
          <div className="overlay-text">
            <h2>{item.text}</h2>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default SectionGallery;
