import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Button, InputField, CountrySelector } from "../constant";
import axios from "axios";
import { baseURL, REGISTER } from "../../Api/Api";
import "./signup.css";

function Signup() {
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [form, setForm] = useState({
    email: "",
    phone: "",
    name: "",
    password: "",
    repassword: "",
  });

  const handleVerification = () => {
    navigate("/verification");
  };

  //Handle Form Change
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  //Handle Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // تأكد أن جميع الحقول تحتوي على بيانات صحيحة
    if (!form.email || !form.phone || !form.name || !form.password || !form.repassword) {
      console.log("Please fill in all fields");
      return;
    }
  
    try {
      const response = await axios.post(`${baseURL}${REGISTER}`, {
        email: form.email,
        phone: form.phone,
        name: form.name,
        password: form.password,
      }, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      console.log("Account created successfully:", response.data);
      handleVerification();
    } catch (err) {
      console.log("Error creating account:", err.response.data); // يعرض تفاصيل الخطأ في الخادم
    }
  };

  return (
    <div>
      <div className="container flex">
        <header className="headerone flex" />
        <div className="bottom-section-signup flex ">
          <div className="left-section-signup flex">
            <div className="div-circle-signup"></div>
            <img className="logo-signup" src={logo} alt="Logo" />
            <h2 className="subtitle-signup">The Construction and Landscaping Company</h2>
          </div>
          <div className="right-section-signup flex">
            <h1 className="title-signup">SignUp</h1>
            <form className="form-signup" onSubmit={handleSubmit}>
              <InputField
                type="email"
                placeholder="example@gmail.com"
                className="input-with-icon"
                label={"EMAIL ADDRESS"}
                name="email"
                onChange={handleChange}
                value={form.email}
              />
              <InputField
                type="tel"
                placeholder="*******************"
                className="input-with-icon"
                label={"PHONE NUMBER"}
                name="phone"
                onChange={handleChange}
                value={form.phone}
              />
              <InputField
                type="text"
                placeholder="@USER_NAME"
                className="input-with-icon"
                label={"USERNAME"}
                name="name"
                onChange={handleChange}
                value={form.name}
              />
              <InputField
                type="password"
                placeholder="*******************"
                className="input-with-icon"
                label={"PASSWORD"}
                name="password"
                onChange={handleChange}
                value={form.password}
              />
              <InputField
                type="password"
                placeholder="*******************"
                className="input-with-icon"
                label={"RE-ENTER PASSWORD"}
                name="repassword"
                onChange={handleChange}
                value={form.repassword}
              />
              <div className="country-container">
                <CountrySelector
                  label="Country:"
                  className="label-signup"
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  value={selectedCountry}
                  options={[
                    { value: "saudi", label: "السعودية" },
                    { value: "egypt", label: "مصر" },
                    { value: "uae", label: "الإمارات" },
                    { value: "jordan", label: "الأردن" },
                    { value: "morocco", label: "المغرب" },
                  ]}
                />
                <CountrySelector
                  label="City:"
                  className="label-signup"
                  onChange={(e) => setSelectedCity(e.target.value)}
                  value={selectedCity}
                  options={[
                    { value: "damascus", label: "دمشق" },
                    { value: "hama", label: "حماة" },
                    { value: "aleppo", label: "حلب" },
                    { value: "homs", label: "حمص" },
                  ]}
                />
              </div>
              <Button type="submit" label="CREATE ACCOUNT" />
            </form>
            <div className="author-choice-pass flex">
              <h2>HAVE AN ACCOUNT?</h2>
              <Link to="/" className="link-signup">LOGIN</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
