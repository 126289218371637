import React, { useState } from "react";
import logo from "../assets/images/logo.png";
import "./constant.css";
import { useNavigate } from "react-router-dom";

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <header className="header-profile flex">
      <div className="headerprofile-leftpart">
        <img src={logo} alt="profile-photo" className="logo-profile" />
        <h1 className="h1-profile">THE CONSTRUCTION AND LANDSCAPING COMPANY</h1>
      </div>
      <div className="headerprofile-secondpart flex">
        {["HOME", "ABOUT US", "PROJECTS"].map((text) => (
          <a href="#" className="profile-a flex" key={text}>
            {text}
          </a>
        ))}
      </div>
      <div className="search-container">
        <input type="text" placeholder="search..." className="search-input" />
      </div>
      <div className="headerprofile-fourthpart flex">
        <a
          href="#"
          className="profile-a flex"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          Menu
        </a>
        {menuOpen && (
          <div className="dropdown-menu">
            {["LANDSCAPING","DECKING", "GARDENING","INTERLOCKING", "FLORAL DESIGN","POOLS","RAILINGS","WOODWOORKING","SHOWROOM","PROFILE","CONTACT US"].map((item) => (
              <a href="#" className="dropdown-item" key={item} onClick={()=> {
                if (item==="PROFILE"){
                  navigate("/profile");
                }
                if (item==="LANDSCAPING"){
                  navigate("/landing");
                }
              }}>
                {item}
              </a>
            ))}
          </div>
        )}
        <button className="profile-button" type="button">
          BOOK NOW
        </button>
      </div>
    </header>
  );
}
export default Header;

export const Button = ({ label, onClick, type }) => (
  <button onClick={onClick} className="button" type={type}>
    {label}
  </button>
);

export const InputField = ({
  label,
  icon,
  type,
  placeholder,
  value,
  onChange,
  className,
  name,
}) => (
  <div className="input-field-container">
    {label && <label className="input-label">{label}</label>}
    <div className="input-icon-wrapper">
      {icon && <i className={`icon ${icon}`}></i>}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={className}
        name={name}
      />
    </div>
  </div>
);

export const CountrySelector = ({
  label,
  className = "div-choose",
  onChange,
  value,
  options = [],
}) => (
  <form className={className}>
    {label && (
      <label className="label-signup" htmlFor="country">
        {label}
      </label>
    )}
    <select
      id="country"
      name="country"
      required
      onChange={onChange}
      value={value}
      className="select-country"
    >
      <option value=""> </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </form>
);
