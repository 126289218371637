import React, { useState } from "react";
import "./login.css";
import logo from '../../assets/images/logo.png';
import { Link, useNavigate } from "react-router-dom";
import { Button, InputField } from "../constant";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const validateForm = () => {
    if (!email || !password) {
      setError("Please fill in all fields ");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }
    setError("");
    navigate("/profile");
  };

  return (
    <div className="container flex">
      <header className="headerone flex" />
      <div className="bottom-section flex">
        <div className="left-section flex">
          <h1>WELCOME</h1>
          <h1>REINVENT YOUR SPACE WITH US</h1>
          <img className="logo" src={logo} alt="Logo" />
          <div className="input-container flex">
            <InputField
              type="email"
              placeholder="example@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="EMAIL ADDRESS"
            />
            <InputField
              type="password"
              placeholder="*******************"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="PASSWORD"
            />
            {error && <p className="error-message">{error}</p>}
            <div className="author-choice">
              <div className="flex">
                <h2>DON’T HAVE AN ACCOUNT?</h2>
                <Link to="/signup" className="link-signup">SIGN UP</Link>
              </div>
              <div className="flex">
                <h2>FORGOT PASSWORD?</h2>
                <Link to="/ChangePass" className="link-signup">CHANGE PASSWORD</Link>
              </div>
            </div>
            
              <Button onClick={validateForm}  label="LOGIN" />
            
          </div>
        </div>
        <div className="right-section flex"></div>
      </div>
    </div>
  );
}

export default Login;
